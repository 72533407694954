import {registerLanguage} from '../utils-lang';

const plural = (x: number) => (x > 1 || x === 0 || x < -1);

registerLanguage('Русский', {
    LOCALE: 'ru-RU',
    LOGIN_INSTRUCTIONS: '<h1>Введите ваши учетные данные</h1>',
    LOGIN_CANDIDATE_ID_LABEL: 'Имя пользователя',
    LOGIN_PASSWORD_LABEL: 'Пароль',
    LOGIN_BUTTON: 'Войти',
    FREE_SPACE: ({quota, used}) => `${used}МБ использовано из доступных ${quota}МБ`,

    CHOOSE_INSTRUCTIONS: ({cid}) => `<p><b>Вы вошли как пользователь ${cid}</b></p>Если это не вы, пожалуйста, выйдите и войдите снова с вашим предоставленным идентификатором пользователя`,
    CHOOSE_PROCTORED_EXAMITY: `<h1>Нажмите на \u201cНачать прокторинг\u201d для доступа к платформе EXAMITY</h1><ul><li>Чтобы создать ваш профиль</li><li>Чтобы подключиться к вашему проктору и начать экзамен</li></ul>`,
    CHOOSE_PROCTORED_GENERIC: (proctorName) => `<h1>Пожалуйста, нажмите на \u201cНачать&nbsp;прокторинг\u201d для доступа к платформе ${proctorName}</h1>`,
    CHOOSE_PROCTORED_SUPPORT: `Если у вас возникнут трудности с подключением к проктору, пожалуйста, свяжитесь с командой поддержки экзамена`,
    CHOOSE_NO_EXAMS: `<h1>Для вас нет доступных экзаменов</h1>`,
    CHOOSE_SELECT_EXAM: `<h1>Пожалуйста, выберите экзамен, который вы сдаете</h1>`,
    CHOOSE_AUTO_START: `<h1>Пожалуйста, подождите, вы будете автоматически подключены, когда ваш экзамен будет открыт</h1>`,
    CHOOSE_AUTO_SUPPORT: `Если вы оказались не подключены после начала экзамена, пожалуйста, свяжитесь с командой поддержки экзамена`,
    CHOOSE_MANUAL_START: `<h1>Введите предоставленный вам PIN-код экзамена</h1>`,
    CHOOSE_MANUAL_SUPPORT: `Если у вас возникнут трудности с началом экзамена, пожалуйста, свяжитесь с командой поддержки экзамена`,
    CHOOSE_PRESS_START: `<h1>Нажмите \u201cПройти выбранный экзамен\u201d для начала</h1>`,
    CHOOSE_EXAM_LABEL: 'Выберите экзамен',
    CHOOSE_MANUAL_PIN_LABEL: 'PIN-код экзамена',
    CHOOSE_AUTO_PIN_LABEL: 'Офлайн PIN-код',
    CHOOSE_EXAM_BUTTON: 'Пройти выбранный экзамен',
    CHOOSE_LOGOUT_BUTTON: 'Выйти',
    CHOOSE_PROCTOR_BUTTON: 'Начать прокторинг',
    CHOOSE_EXAM_HINT: 'Выберите экзамен для прохождения...',
    CHOOSE_WAITING_TO_START: 'Экзамены, ожидающие начала',
    CHOOSE_ALREADY_STARTED: 'Экзамены, которые уже начались',
    CHOOSE_OFFLINE_BUTTON: 'Офлайн-режим',

    ERROR_NETWORK: 'Ошибка сети',
    ERROR_USER_PASS: '<b>Ошибка аутентификации. Такого аккаунта нет, или вы ввели неверные учетные данные.</b>',
    ERROR_CONNECTION_TIMEOUT: 'Тайм-аут соединения',
    ERROR_HTTP: ({code}) => `Ошибка сервера ${code ? ` (HTTP ${code})` : ''}`,
    ERROR_PROXY: 'Сервер занят, пожалуйста, попробуйте позже.',
    ERROR_UNKNOWN: ({message}) => `Ошибка ${message ? ` [${message}]` : ''}`,
    ERROR_STORAGE: "У вас закончилось место для хранения данных. Пожалуйста, освободите место или перейдите на другое устройство.",
    ERROR_PIN: '<b>Неверный PIN-код или выбран неправильный экзамен<b>',
    ERROR_CANDIDATE_NOT_FOUND: 'Идентификатор пользователя либо введен неверно, либо не зарегистрирован для этого экзамена.',
    ERROR_RESEND: ({err}) => `<b>${err}</b><p>Невозможно связаться с сервером. Вы можете продолжить экзамен офлайн, и ответы будут сохранены локально на этом устройстве, но их нужно будет отправить на сервер, прежде чем вы сможете завершить экзамен. Вы не сможете видеть ответы, ранее отправленные с другого устройства. Если сеть восстановится, все ответы автоматически сохранятся на сервере.</p>`,
    ERROR_DOWNLOAD: 'Невозможно загрузить экзамен',

    DOWNLOAD_TITLE: ({downloadCount}) => `Загрузка ${downloadCount} экзамен${plural(downloadCount) ? 'ов' : 'а'}, пожалуйста, подождите...`,
    DOWNLOAD_DESCRIPTION: ({freeSpace}) => `<p>Загрузка может занять несколько минут в зависимости от скорости вашего сетевого соединения</p>${freeSpace}`,
    DOWNLOAD_COUNT_EXCEEDED: ({examTitle}) => `Превышено количество загрузок: ${examTitle}`,

    PREPARING_TITLE: 'Подготовка экзамена, пожалуйста, подождите...',
    PREPARING_DESCRIPTION: ({freeSpace}) => `<p>Подготовка может занять несколько минут в зависимости от характеристик вашего компьютера<p>${freeSpace}`,

    CONTROL_OVERVIEW: 'ОБЗОР',
    CONTROL_LANGUAGE: 'ЯЗЫК',
    CONTROL_FINISH: 'ЗАВЕРШИТЬ',
    CONTROL_CALCULATOR: 'КАЛЬКУЛЯТОР',
    CONTROL_COLOUR: 'ЦВЕТ',
    CONTROL_VERSION: 'ВЕРСИЯ',
    CONTROL_USERID: 'ID ПОЛЬЗОВАТЕЛЯ',
    CONTROL_PREVIOUS: 'ПРЕДЫДУЩИЙ',
    CONTROL_NEXT: 'СЛЕДУЮЩИЙ',
    CONTROL_PREVIOUS_CASE: 'ПРЕДЫДУЩИЙ СЛУЧАЙ',
    CONTROL_NEXT_CASE: 'СЛЕДУЮЩИЙ СЛУЧАЙ',
    CONTROL_PREVIOUS_CANDIDATE: 'ПРЕДЫДУЩИЙ КАНДИДАТ',
    CONTROL_NEXT_CANDIDATE: 'СЛЕДУЮЩИЙ КАНДИДАТ',
    CONTROL_PREVIOUS_STATION: 'ПРЕДЫДУЩАЯ СТАНЦИЯ',
    CONTROL_NEXT_STATION: 'СЛЕДУЮЩАЯ СТАНЦИЯ',
    CONTROL_NOTES: 'ЗАМЕТКИ',
    CONTROL_CONNECT: 'ПОДКЛЮЧИТЬ',
    CONTROL_CONNECTING: 'ПОДКЛЮЧЕНИЕ',
    CONTROL_DISCONNECT: 'ОТКЛЮЧИТЬ',
    CONTROL_DISCONNECTING: 'ОТКЛЮЧЕНИЕ',
    CONTROL_ID_VALIDATE: ({id}) => `ПОДТВЕРДИТЬ ${id}`,
    CONTROL_ID_INVALIDATE: ({id}) => `ОТМЕНИТЬ ${id}`,
    CONTROL_MUTE: 'ВЫКЛЮЧИТЬ ЗВУК',
    CONTROL_UNMUTE: 'ВКЛЮЧИТЬ ЗВУК',
    CONTROL_CAMERA: 'КАМЕРА',
    CONTROL_SPEAKERS: 'ДИНАМИКИ',
    CONTROL_MICROPHONE: 'МИКРОФОН',
    CONTROL_HIGHLIGHT: '(СНЯТЬ) ВЫДЕЛЕНИЕ',

    ID_CHECK_INVALIDATE_CONFIRM: 'Вы уверены, что хотите аннулировать этот идентификатор?',

    TITLE_ROUND: 'раунд',
    TITLE_STATION

: 'станция',
    TITLE_CIRCUIT: 'циркуит',
    TITLE_CASE: ({n, m}) => `случай&nbsp;<b>${n}</b>&nbsp;из&nbsp;<b>${m}</b>`,
    TITLE_CONNECT_TO: ({factor}) => `подключиться&nbsp;к&nbsp;${factor}`,

    NOTIFICATION_CONNECT: '<center>Нажмите кнопку <b>ПОДКЛЮЧИТЬ</b> выше, чтобы начать.</center>',
    NO_CANDIDATE: 'Нет кандидата на этот раунд.',
    REST_STATION: 'Это станция отдыха.',

    MEETING_POOR_CONNECTION: 'Плохое соединение: могут возникнуть проблемы с качеством аудио и видео.',
    MEETING_STATUS_ERROR: ({err}) => `Статус выхода из встречи: ${err}`,

    TIMER_REMAINING: 'ОСТАЛОСЬ ВРЕМЕНИ',
    TIMER_ELAPSED: 'ПРОШЕДШЕЕ ВРЕМЯ',
    TIMER_ROUND_REMAINING: 'ОСТАЛОСЬ РАУНДОВ',

    OVERVIEW_TITLE: 'Обзор',
    OVERVIEW_NO_CANDIDATE: 'Нет кандидатов',
    RESOURCE_RESTRICTED_SHOW: 'Показать',
    RESOURCE_RESTRICTED_HIDE: 'Скрыть',

    ANSWER_OPTIONAL: 'необязательный',
    ANSWER_REQUIRED: 'обязательный',
    ANSWER_TITLE: 'Ответ',
    ANSWER_FLAG_TIP: 'Отметить этот вопрос',
    SBA_ANSWER_TIP: 'Выбрать этот ответ',
    SBA_ELIMINATE_TIP: 'Исключить этот ответ',
    NOT_ANSWERED: 'Не отвечено',

    PAUSED_TITLE: 'Экзамен приостановлен',
    PAUSED_DESCRIPTION: '<p>Экзамен был <strong>приостановлен</strong> удаленно администратором.<p>',

    STOPPED_TITLE: 'Экзамен завершен',
    STOPPED_CLEANUP: '<p>Идет очистка, пожалуйста, подождите...<p>',

    MARKSHEET_INVALID: ({invalid}) => `Для следующих обязательных критериев требуется ответ:<br><br><b>${invalid}</b><br><br><div style="text-align:left"><b>ОК</b> - чтобы покинуть этот лист оценок.<br><b>ОТМЕНА</b> - чтобы остаться на этом листе оценок и увидеть, какие критерии отсутствуют.</div>`,

    FINISH_TITLE: 'Готовы закончить?',
    FINISH_DESCRIPTION: 'Нажмите \u201cОтправить экзамен\u201d перед закрытием вкладки или окна',
    FINISH_ANSWERED: 'Вы ответили на все вопросы',
    FINISH_UNANSWERED: ({totalUnanswered}) => `У вас ${totalUnanswered} неотвеченных вопрос${plural(totalUnanswered) ? 'ов' : ''}`,
    FINISH_MANDATORY_UNANSWERED: ({totalUnanswered, mandatoryUnanswered}) => `У вас ${totalUnanswered} неотвеченных вопрос${plural(totalUnanswered) ? 'ов' : ''} (${mandatoryUnanswered} обязательных)`,
    FINISH_SUBMITTED: '<p><b>Все ваши ответы были отправлены на сервер.</b></p>',
    FINISH_UNSUBMITTED: ({unsubmitted}) => `<p>У вас <strong>${unsubmitted}</strong> ответ${plural(unsubmitted) ? 'ов' : ''}, который${plural(unsubmitted) ? 'е' : ''} не был отправлен на сервер.</p><p>Продолжение экзамена безопасно, так как ответы будут сохранены на вашем устройстве.</p>`,
    FINISH_CONTINUE: 'Продолжить экзамен',
    FINISH_NOW: 'Отправить экзамен',

    INFO_TITLE: 'Инструкции',
    WARN_TITLE: 'Предупреждение',
    ALERT_TITLE: 'Что-то пошло не так',
    ALERT_OK: 'ОК',
    ALERT_CANCEL: 'ОТМЕНА',
    ALERT_RETRY: 'ПОВТОРИТЬ',
    ALERT_QUIT: 'ВЫХОД',
    ALERT_IGNORE: "ИГНОРИРОВАТЬ",

    MEDIA_DEVICES_NOT_SUPPORTED: 'Этот браузер не поддерживает доступ к камере и микрофону',
    CAMERA_ERROR: 'Ошибка доступа к камере',
    CAMERA_INSTRUCTIONS: ({err}) => `<p>[${err}]</p><ul><li>Убедитесь, что ваше устройство имеет встроенную камеру или внешняя камера подключена</li><li>Возможно, вам нужно будет разрешить доступ к камере для <b>risr/</b>assess в настройках конфиденциальности и безопасности вашего браузера</li><li>Возможно, вам нужно будет разрешить доступ к камере для вашего браузера в настройках конфиденциальности и безопасности вашего устройства</li></ul>`,
    MICROPHONE_ERROR: "Ошибка доступа к микрофону",
    MICROPHONE_INSTRUCTIONS: ({err}) => `<p>[${err}]</p><ul><li>Убедитесь, что ваше устройство имеет встроенный микрофон или микрофон подключен</li><li>Возможно, вам нужно будет разрешить доступ к микрофону для <b>risr/</b>assess в настройках конфиденциальности и безопасности вашего браузера</li><li>Возможно, вам нужно будет разрешить доступ к микрофону для вашего браузера в настройках конфиденциальности и безопасности вашего устройства</li></ul>`,
    NO_CAMERAS: 'Камеры не найдены',
    NO_MICROPHONES: 'Микрофоны не найдены',
    SCREEN_SHARE_PERMISSION: '<p>Для этого экзамена вам необходимо <i>разрешить</i> делиться экраном с экзаменатором.</p><ul><li>Вы должны поделиться всем экраном, поэтому если вам предоставляются варианты, вы должны выбрать "весь экран", "полный экран" или эквивалент.</li><li>Возможно, вам нужно будет разрешить "делиться экраном" или "запись экрана" для <b>risr/</b>assess в настройках конфиденциальности и безопасности вашего браузера</li><li>Возможно, вам нужно будет разрешить "делиться экраном" или "запись экрана" для вашего браузера в настройках конфиденциальности и безопасности вашего устройства</li></ul>',
    SCREEN_SHARE_FAILED: '<b>Показ экрана в настоящее время <i>заблокирован</i>.</b>',

    VIEWER_RESET: 'СБРОСИТЬ ПРОСМОТР',
    VIEWER_SELECT: 'ВЫБРАТЬ ДЕЙСТВИЕ КЛИКА',
    VIEWER_PREV: 'ПРЕДЫДУЩАЯ СТРАНИЦА',
    VIEWER_NEXT: 'СЛЕДУЮЩАЯ СТРАНИЦА',
    VIEWER_FULLSCREEN: 'ПЕРЕКЛЮЧИТЬ ПОЛНОЭКРАННЫЙ РЕЖИМ',
    VIEWER_CLOSE: 'ЗАКРЫТЬ ПРОСМОТРЩИК',
    VIEWER_BROADCAST: 'ТРАНСЛИРОВАТЬ ИЗМЕНЕНИЯ',
    VIEWER_UNDO: 'ОТМЕНИТЬ ИЗМЕНЕНИЯ',
    VIEWER_REDO: 'ПОВТОРИТЬ ИЗМЕНЕНИЯ',

    LOGOUT_MESSAGE: '<p>В настоящее время вы вышли из системы, нажмите ниже, чтобы войти.</p>',
    LOGIN_AGAIN: 'ВОЙТИ',
    P4B_BACKGROUNDED: 'Эта вкладка была приостановлена, потому что другая вкладка экзамена <b>risr/</b>assess была активна. Нажмите ОК, чтобы возобновить.',
    NEWER_VERSION_AVAILABLE: ({version, latest}) => `<p>Доступна новая версия <b>risr/</b>assess</p><p>установлена: ${version}<br/>доступна: ${latest}</p>`,
});