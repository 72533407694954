import {registerLanguage} from '../utils-lang';

const plural = (x: number) => (x > 1 || x === 0 || x < -1);

registerLanguage('English', {
    LOCALE: 'en-GB',
    LOGIN_INSTRUCTIONS: '<h1>Enter your login credentials</h1>',
    LOGIN_CANDIDATE_ID_LABEL: 'Username',
    LOGIN_PASSWORD_LABEL: 'Password',
    LOGIN_BUTTON: 'Log in',
    FREE_SPACE: ({quota, used}) => `${used}MB storage used of ${quota}MB available`,

    CHOOSE_INSTRUCTIONS: ({cid}) => `<p><b>You are logged in as user ${cid}</b></p>If this is not you, please log out and log back in with your provided user ID`,
    CHOOSE_PROCTORED_EXAMITY: `<h1>Please click on \u201cStart&nbsp;proctoring\u201d to access the EXAMITY platform</h1><ul><li>To create your profile</li><li>To connect to your proctor and start your exam</li></ul>`,
    CHOOSE_PROCTORED_GENERIC: ({proctorName}) => `<h1>Please click on \u201cStart&nbsp;proctoring\u201d to access the ${proctorName} platform</h1>`,
    CHOOSE_PROCTORED_SUPPORT: `If you have difficulties connecting to the proctor, please contact your exam support team`,
    CHOOSE_NO_EXAMS: `<h1>There are no exams available for you to take</h1>`,
    CHOOSE_SELECT_EXAM: `<h1>Please select the exam you are taking</h1>`,
    CHOOSE_AUTO_START: `<h1>Please wait, you will be automatically connected when your exam is opened</h1>`,
    CHOOSE_AUTO_SUPPORT: `If you are not connected after the start time of your exam, please contact your exam support team`,
    CHOOSE_MANUAL_START: `<h1>Enter the exam PIN you have been provided with</h1>`,
    CHOOSE_MANUAL_SUPPORT: `If you have difficulties starting the exam, please contact your exam support team`,
    CHOOSE_PRESS_START: `<h1>Click \u201cTake&nbsp;chosen&nbsp;exam\u201d to start</h1>`,
    CHOOSE_EXAM_LABEL: 'Choose exam',
    CHOOSE_MANUAL_PIN_LABEL: 'Exam PIN',
    CHOOSE_AUTO_PIN_LABEL: 'Offline PIN',
    CHOOSE_EXAM_BUTTON: 'Take chosen exam',
    CHOOSE_LOGOUT_BUTTON: 'Logout',
    CHOOSE_PROCTOR_BUTTON: 'Start proctoring',
    CHOOSE_EXAM_HINT: 'Choose exam to take...',
    CHOOSE_WAITING_TO_START: 'Exams waiting to start',
    CHOOSE_ALREADY_STARTED: 'Exams already started',
    CHOOSE_OFFLINE_BUTTON: 'Offline mode',

    ERROR_NETWORK: 'Network error',
    ERROR_USER_PASS: '<b>Authentication failed. There is no such account, or you have given invalid credentials.</b>',
    ERROR_CONNECTION_TIMEOUT: 'Connection timeout',
    ERROR_HTTP: ({code}) => `Server error ${code ? ` (HTTP ${code})` : ''}`,
    ERROR_PROXY: 'Server busy, please try again.',
    ERROR_UNKNOWN: ({message}) => `Error ${message ? ` [${message}]` : ''}`,
    ERROR_STORAGE: "You have run out of storage space. Please make more space available, or move to a different device.",
    ERROR_PIN: '<b>Incorrect PIN, or wrong exam chosen<b>',
    ERROR_CANDIDATE_NOT_FOUND: 'User ID entered incorrectly, or is not registered for this exam.',
    ERROR_RESEND: ({err}) => `<b>${err}</b><p>Unable to contact server. You can continue the exam offline and responses will be stored locally on this device, but they will need to be submitted to the server before you can finish the exam. You will not be able to see any responses previously submitted from a different device. If the network recovers all responses will be saved to the server automatically.</p>`,
    ERROR_DOWNLOAD: 'Unable to download exam',

    DOWNLOAD_TITLE: ({downloadCount}) => `Downloading ${downloadCount} exam${plural(downloadCount) ? 's' : ''}, please wait...`,
    DOWNLOAD_DESCRIPTION: ({freeSpace}) => `<p>Downloading can take several minutes depending on your network connectivity</p>${freeSpace}`,
    DOWNLOAD_COUNT_EXCEEDED: ({examTitle}) => `Download count exceeded: ${examTitle}`,

    PREPARING_TITLE: 'Preparing exam, please wait...',
    PREPARING_DESCRIPTION: ({freeSpace}) => `<p>Preparing can take several minutes depending on your computer specification<p>${freeSpace}`,

    CONTROL_OVERVIEW: 'OVERVIEW',
    CONTROL_LANGUAGE: 'LANGUAGE',
    CONTROL_FINISH: 'FINISH',
    CONTROL_CALCULATOR: 'CALCULATOR',
    CONTROL_COLOUR: 'COLOUR',
    CONTROL_VERSION: 'VERSION',
    CONTROL_USERID: 'USER ID',
    CONTROL_PREVIOUS: 'PREVIOUS',
    CONTROL_NEXT: 'NEXT',
    CONTROL_PREVIOUS_CASE: 'PREV CASE',
    CONTROL_NEXT_CASE: 'NEXT CASE',
    CONTROL_PREVIOUS_CANDIDATE: 'PREV CANDIDATE',
    CONTROL_NEXT_CANDIDATE: 'NEXT CANDIDATE',
    CONTROL_PREVIOUS_STATION: 'PREV STATION',
    CONTROL_NEXT_STATION: 'NEXT STATION',
    CONTROL_NOTES: 'NOTES',
    CONTROL_CONNECT: 'CONNECT',
    CONTROL_CONNECTING: 'CONNECTING',
    CONTROL_DISCONNECT: 'DISCONNECT',
    CONTROL_DISCONNECTING: 'DISCONNECTING',
    CONTROL_ID_VALIDATE: ({id}) => `VALIDATE ${id}`,
    CONTROL_ID_INVALIDATE: ({id}) => `INVALIDATE ${id}`,
    CONTROL_MUTE: 'MUTE',
    CONTROL_UNMUTE: 'UNMUTE',
    CONTROL_CAMERA: 'CAMERA',
    CONTROL_SPEAKERS: 'SPEAKERS',
    CONTROL_MICROPHONE: 'MICROPHONE',
    CONTROL_HIGHLIGHT: '(UN)HIGHLIGHT',

    ID_CHECK_INVALIDATE_CONFIRM: 'Are you sure you want to invalidate this ID?',

    TITLE_ROUND: 'round',
    TITLE_STATION: 'station',
    TITLE_CIRCUIT: 'circuit',
    TITLE_CASE: ({n, m}) => `case&nbsp;<b>${n}</b>&nbsp;of&nbsp;<b>${m}</b>`,
    TITLE_CONNECT_TO: ({factor}) => `connect&nbsp;to&nbsp;${factor}`,

    NOTIFICATION_CONNECT: '<center>Click the <b>CONNECT</b> button above to start.</center>',
    NO_CANDIDATE: 'No candidate for this round.',
    REST_STATION: 'This is a rest station.',

    MEETING_POOR_CONNECTION: 'Poor connection: you may have audio and video quality issues.',
    MEETING_STATUS_ERROR: ({err}) => `Meeting exit status: ${err}`,

    TIMER_REMAINING: 'TIME REMAINING',
    TIMER_ELAPSED: 'ELAPSED TIME',
    TIMER_ROUND_REMAINING: 'ROUND REMAINING',

    OVERVIEW_TITLE: 'Overview',
    OVERVIEW_NO_CANDIDATE: 'No Candidate',
    RESOURCE_RESTRICTED_SHOW: 'Show',
    RESOURCE_RESTRICTED_HIDE: 'Hide',

    ANSWER_OPTIONAL: 'optional',
    ANSWER_REQUIRED: 'required',
    ANSWER_TITLE: 'Answer',
    ANSWER_FLAG_TIP: 'Flag this question',
    SBA_ANSWER_TIP: 'Select this answer',
    SBA_ELIMINATE_TIP: 'Eliminate this answer',
    NOT_ANSWERED: 'Not Answered',

    PAUSED_TITLE: 'Exam paused',
    PAUSED_DESCRIPTION: '<p>The exam has been <strong>paused</strong> remotely by an administrator.<p>',

    STOPPED_TITLE: 'Exam Finished',
    STOPPED_CLEANUP: '<p>Cleaning up, please wait...<p>',

    MARKSHEET_INVALID: ({invalid}) => `The following mandatory criteria need a response:<br><br><b>${invalid}</b><br><br><div style="text-align:left"><b>OK</b> - to leave this marksheet.<br><b>CANCEL</b> - to stay on this marksheet and see which criteria are missing.</div>`,

    FINISH_TITLE: 'Ready to finish?',
    FINISH_DESCRIPTION: 'Click \u201cSubmit exam\u201d before closing the tab or window',
    FINISH_ANSWERED: 'You have answered all the questions',
    FINISH_UNANSWERED: ({totalUnanswered}) => `You have ${totalUnanswered} unanswered question${plural(totalUnanswered) ? 's' : ''}`,
    FINISH_MANDATORY_UNANSWERED: ({totalUnanswered, mandatoryUnanswered}) => `You have ${totalUnanswered} unanswered question${plural(totalUnanswered) ? 's' : ''} (${mandatoryUnanswered} mandatory)`,
    FINISH_SUBMITTED: '<p><b>All of your answers have been submitted to the server.</b></p>',
    FINISH_UNSUBMITTED: ({unsubmitted}) => `<p>You have <strong>${unsubmitted}</strong> answer${plural(unsubmitted) ? 's' : ''} that ${plural(unsubmitted) ? 'are' : 'is'} not submitted to the server.</p><p>It is safe to continue the exam as answers will be stored on your device.</p>`,
    FINISH_CONTINUE: 'Continue exam',
    FINISH_NOW: 'Submit exam',

    INFO_TITLE: 'Instructions',
    WARN_TITLE: 'Warning',
    ALERT_TITLE: 'Something went wrong',
    ALERT_OK: 'OK',
    ALERT_CANCEL: 'CANCEL',
    ALERT_RETRY: 'RETRY',
    ALERT_QUIT: 'QUIT',
    ALERT_IGNORE: "CANCEL",

    MEDIA_DEVICES_NOT_SUPPORTED: 'This browser does not support cameras and microphones',
    CAMERA_ERROR: 'Error accessing camera',
    CAMERA_INSTRUCTIONS: ({err}) => `<p>[${err}]</p><ul><li>Make sure your device has a built in camera, or you have a camera plugged in</li><li>You may need to enable camera access for <b>risr/</b>assess in your browsers privicy and security settings</li><li>You may need to enable camera access for your browser in your device privicy and security settings</li></ul>`,
    MICROPHONE_ERROR: "Error accessing microphone",
    MICROPHONE_INSTRUCTIONS: ({err}) => `<p>[${err}]</p><ul><li>Make sure your device has a built in microphone, or you have a microphone plugged in</li><li>You may need to enable microphone access for <b>risr/</b>assess in your browsers privicy and security settings</li><li>You may need to enable microphone access for your browser in your device privicy and security settings</li></ul>`,
    NO_CAMERAS: 'No cameras found',
    NO_MICROPHONES: 'No microphones found',
    SCREEN_SHARE_PERMISSION: '<p>For this exam you need to <i>allow</i> your screen to be shared with the examiner.</p><ul><li>You must share the whole screen, so if you are given options you should select "whole screen", "entire screen" or equivalent.</li><li>You may need to allow "screen sharing" or "screen recording" for <b>risr/</b>assess in your browser privicy and security settings</li><li>You may need to allow "screen sharing" or "screen recording" for your browser in your device privicy and security settings</li></ul',
    SCREEN_SHARE_FAILED: '<b>Screen sharing is currently <i>blocked</i>.</b>',

    VIEWER_RESET: 'RESET VIEW',
    VIEWER_SELECT: 'SELECT CLICK ACTION',
    VIEWER_PREV: 'PREVIOUS PAGE',
    VIEWER_NEXT: 'NEXT PAGE',
    VIEWER_FULLSCREEN: 'TOGGLE FULLSCREEN',
    VIEWER_CLOSE: 'CLOSE VIEWER',
    VIEWER_BROADCAST: 'BROADCAST CHANGES',
    VIEWER_UNDO: 'UNDO CHANGES',
    VIEWER_REDO: 'REDO CHANGES',

    LOGOUT_MESSAGE: '<p>You are currently logged out, click below to login.</p>',
    LOGIN_AGAIN: 'LOGIN',
    P4B_BACKGROUNDED: 'This tab has been suspended because another <b>risr/</b>assess exam tab has been active. Click OK to resume.',
    NEWER_VERSION_AVAILABLE: ({version, latest}) => `<p>A newer version of <b>risr/</b>assess is available</p><p>installed: ${version}<br/>available: ${latest}</p>`,
});
