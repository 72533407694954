import {registerLanguage} from '../utils-lang';

const plural = (x: number) => (x > 1 || x < -1);

registerLanguage('Français', {
    LOCALE: 'fr-CA',
    LOGIN_INSTRUCTIONS: '<h1>Saisissez vos identifiants de connexion</h1>',
    LOGIN_CANDIDATE_ID_LABEL: "Nom d'utilisateur",
    LOGIN_PASSWORD_LABEL: 'Mot de passe',
    LOGIN_BUTTON: 'Connexion',
    FREE_SPACE: ({quota, used}) => `[${used}Mo utilisés sur ${quota}Mo disponibles]`,

    CHOOSE_INSTRUCTIONS: ({cid}) => `${cid ? `<p><b>Vous avez ouvert une session en tant qu’utilisateur·ice ${cid}</b></p> Si ce n'est pas vous, veuillez fermer la session et vous reconnecter avec votre identifiant fourni` : ''}`,
    CHOOSE_PROCTORED_EXAMITY: `<h1>Cliquez sur <i>Démarrer&nbsp;la&nbsp;surveillance</i> pour accéder à la plateforme EXAMITY</h1><ul><li>Pour créer votre profil</li><li>Pour vous connecter au surveillant et commencer l’examen</li></ul>`,
    CHOOSE_PROCTORED_GENERIC: ({proctorName}) =>`<h1>Cliquez sur <i>Démarrer&nbsp;la&nbsp;surveillance</i> pour accéder à la plateforme ${proctorName}</h1>`,
    CHOOSE_PROCTORED_SUPPORT: `Si vous rencontrez des difficultés pour vous connecter au surveillant, veuillez contacter votre équipe d'assistance aux examens`,
    CHOOSE_NO_EXAMS: `Il n'y a pas d'examen disponible pour vous`,
    CHOOSE_SELECT_EXAM: `<h1>Veuillez sélectionner l'examen que vous passez</h1>`,
    CHOOSE_AUTO_START: `<h1>Veuillez attendre; la connexion se fera automatiquement au début de l’examen</h1>`,
    CHOOSE_AUTO_SUPPORT: `Si la connexion n’est pas établie après le début de l’examen, communiquez avec l’équipe de soutien aux examens`,
    CHOOSE_MANUAL_START: `<h1>Saisissez le code NIP de l’examen qui vous a été fourni</h1>`,
    CHOOSE_MANUAL_SUPPORT: `Si vous rencontrez des difficultés pour démarrer l'examen, veuillez contacter votre équipe d'assistance aux examens`,
    CHOOSE_PRESS_START: `Cliquez sur « Commencer l’examen sélectionné » pour commencer`,
    CHOOSE_EXAM_LABEL: `Choisissez un examen`,
    CHOOSE_MANUAL_PIN_LABEL: `NIP de l’examen`,
    CHOOSE_AUTO_PIN_LABEL: 'NIP hors ligne',
    CHOOSE_EXAM_BUTTON: 'Commencer l’examen sélectionné',
    CHOOSE_LOGOUT_BUTTON: 'Fermer la session',
    CHOOSE_PROCTOR_BUTTON: 'Démarrer la suveillance',
    CHOOSE_EXAM_HINT: 'Sélectionner l’examen…',
    CHOOSE_WAITING_TO_START: 'Examens en attente de commencer',
    CHOOSE_ALREADY_STARTED: 'Examens déjà commencés',
    CHOOSE_OFFLINE_BUTTON: 'Mode hors ligne',

    ERROR_NETWORK: 'Erreur réseau',
    ERROR_USER_PASS: "<b>Authentification échouée. Il n'existe pas de compte de ce type ou vous avez fourni des informations d'identification non valides.</b>",
    ERROR_CONNECTION_TIMEOUT: 'Délai d’attente de connexion',
    ERROR_HTTP: ({code}) => `Erreur de serveur ${code ? ` (HTTP ${code})` : ''}`,
    ERROR_PROXY: `Serveur occupé, veuillez réessayer.`,
    ERROR_UNKNOWN: ({message}) => `Erreur ${message ? ` [${message}]` : ''}`,
    ERROR_STORAGE: "L’espace de stockage est saturé. Libérez de l’espace ou utilisez un autre ordinateur.",
    ERROR_PIN: '<b>Le NIP ou l’examen sélectionné est incorrect</b>',
    ERROR_CANDIDATE_NOT_FOUND: 'Le n<sup>o</sup> d’identification est incorrect ou n’est pas inscrit pour cet examen. ',
    ERROR_RESEND: ({err}) => `<b>${err}</b><p>Impossible de contacter le serveur. Vous pouvez continuer l’examen hors ligne et les réponses seront enregistrées localement sur cet appareil, mais elles devront être soumises au serveur avant de pouvoir terminer l’examen. Vous ne pourrez pas voir les réponses précédemment soumises à partir d’un autre appareil. Si la connection au réseau se rétablit, toutes les réponses seront automatiquement enregistrées sur le serveur.</p>`,
    ERROR_DOWNLOAD: `Impossible de télécharger l'examen`,

    DOWNLOAD_TITLE: ({downloadCount}) => `Téléchargement de ${downloadCount} examen${plural(downloadCount) ? 's' : ''}, veuillez attendre…`,
    DOWNLOAD_DESCRIPTION: ({freeSpace}) => `<p>Le téléchargement peut prendre plusieurs minutes en fonction de votre connectivité réseau.</p>${freeSpace}`,
    DOWNLOAD_COUNT_EXCEEDED: ({examTitle}) => `Nombre de téléchargements dépassé: ${examTitle}`,

    PREPARING_TITLE: 'Préparation de l’examen, veuillez attendre…',
    PREPARING_DESCRIPTION: ({freeSpace}) => `<p>La préparation peut prendre plusieurs minutes selon les spécifications de votre ordinateur.</p>${freeSpace}`,

    CONTROL_OVERVIEW: 'APERÇU',
    CONTROL_LANGUAGE: 'LANGUE',
    CONTROL_FINISH: 'TERMINER',
    CONTROL_CALCULATOR: 'CALCULATRICE',
    CONTROL_COLOUR: 'COULEUR',
    CONTROL_VERSION: 'VERSION',
    CONTROL_USERID: 'ID UTILILSATEUR',
    CONTROL_PREVIOUS: 'PRÉCÉDENTE',
    CONTROL_NEXT: 'SUIVANTE',
    CONTROL_PREVIOUS_CASE: 'CAS PRÉCÉDENT',
    CONTROL_NEXT_CASE: 'CAS SUIVANT',
    CONTROL_PREVIOUS_CANDIDATE: 'CANDIDAT(E) PRÉCÉDENT(E)',
    CONTROL_NEXT_CANDIDATE: 'CANDIDAT(E) SUIVANT(E)',
    CONTROL_PREVIOUS_STATION: 'STATION PRÉCÉDENTE',
    CONTROL_NEXT_STATION: 'STATION SUIVANTE',
    CONTROL_NOTES: 'NOTES',
    CONTROL_CONNECT: 'CONNECTER',
    CONTROL_CONNECTING: 'CONNECTANT',
    CONTROL_DISCONNECT: 'DÉCONNECTER',
    CONTROL_DISCONNECTING: 'DÉCONNECTANT',
    CONTROL_ID_VALIDATE: ({id}) => `VALIDER ${id}`,
    CONTROL_ID_INVALIDATE: ({id}) => `INVALIDER ${id}`,
    CONTROL_MUTE: 'MUET',
    CONTROL_UNMUTE: 'RÉACTIVER',
    CONTROL_CAMERA: 'VIDÉO',
    CONTROL_SPEAKERS: 'HAUT-PARLEUR',
    CONTROL_MICROPHONE: 'MICROPHONE',
    CONTROL_HIGHLIGHT: '(DE)SURLIGNEUR',

    ID_CHECK_INVALIDATE_CONFIRM: 'Voulez-vous vraiment invalider cette pièce d’identité?',

    TITLE_ROUND: 'tour',
    TITLE_STATION: 'station',
    TITLE_CIRCUIT: 'circuit',
    TITLE_CASE: ({n, m}) => `cas&nbsp;<b>${n}</b>&nbsp;sur&nbsp;<b>${m}</b>`,
    TITLE_CONNECT_TO: ({factor}) => `connecté&nbsp;à&nbsp;${factor}`,

    NOTIFICATION_CONNECT: '<center>Cliquez sur le bouton <b>CONNECTER</b>  ci-dessus pour commencer.</center>',
    NO_CANDIDATE: 'Pas de candidat à ce tour.',
    REST_STATION: `Ceci est une station de repos`,

    MEETING_POOR_CONNECTION: 'Mauvaise connexion: vous pouvez avoir des problèmes de qualité audio et vidéo.',
    MEETING_STATUS_ERROR: ({err}) => `État de sortie de la réunion: ${err}`,

    TIMER_REMAINING: 'TEMPS RESTANT',
    TIMER_ELAPSED: 'TEMPS ÉCOULÉ',
    TIMER_ROUND_REMAINING: 'TEMPS RESTANT',

    OVERVIEW_TITLE: 'Aperçu',
    OVERVIEW_NO_CANDIDATE: 'pas de candidat',
    RESOURCE_RESTRICTED_SHOW: 'Afficher',
    RESOURCE_RESTRICTED_HIDE: 'Masquer',

    ANSWER_OPTIONAL: 'facultative',
    ANSWER_REQUIRED: 'obligatoire',
    ANSWER_TITLE: 'Réponse',
    ANSWER_FLAG_TIP: 'Marquer cette question',
    SBA_ANSWER_TIP: 'Sélectionner cette réponse',
    SBA_ELIMINATE_TIP: 'Éliminer cette réponse',
    NOT_ANSWERED: 'Réponse non fournie',

    PAUSED_TITLE: 'Examen mis en pause',
    PAUSED_DESCRIPTION: '<p>Un administrateur <strong>a mis l’examen en pause</strong> à distance.<p>',

    STOPPED_TITLE: 'Examen terminé',
    STOPPED_CLEANUP: '<p>Nettoyage en cours; veuillez attendre...<p>',

    MARKSHEET_INVALID: ({invalid}) => `Les critères obligatoires suivants nécessitent une réponse:\n\n${invalid}\n\nOK - pour quitter cette feuille de notes.\n\nCANCEL - pour rester sur cette feuille de notes et voir quels critères manquent.`,

    FINISH_TITLE: 'Vous avez vraiment terminé?',
    FINISH_DESCRIPTION: 'Appuyez sur « Soumettre l’examen » avant de fermer l’onglet ou la fenêtre.</p>',
    FINISH_ANSWERED: 'Vous avez répondu à toutes les questions',
    FINISH_UNANSWERED: ({totalUnanswered}) => `Il reste <strong>${totalUnanswered}</strong> question${plural(totalUnanswered) ? 's' : ''} sans réponse`,
    FINISH_MANDATORY_UNANSWERED: ({totalUnanswered, mandatoryUnanswered}) => `Il reste <strong>${totalUnanswered}</strong> question${plural(totalUnanswered) ? 's' : ''} (${mandatoryUnanswered} obligatoire) ${plural(totalUnanswered) ? 's' : ''} sans réponse`,
    FINISH_SUBMITTED: '<p><b>Toutes vos réponses ont été envoyées au serveur.</b></p>',
    FINISH_UNSUBMITTED: ({unsubmitted}) => `<p>Il reste <strong>${unsubmitted}</strong> réponse${plural(unsubmitted) ? 's' : ''} à envoyer au serveur.</p><p>Vous pouvez poursuivre l’examen; les réponses seront enregistrées dans votre ordinateur.</p><p>Les réponses non envoyées sont causées par des problèmes de réseau; vérifiez si le vôtre fonctionne et réessayez avec le bouton ci-dessous. Vous devez soumettre ces réponses avant de pouvoir terminer l’examen. Communiquez avec l’équipe de soutien aux examens au besoin.</p>`,
    FINISH_CONTINUE: 'Poursuivre l’examen',
    FINISH_NOW: 'Soumettre l’examen',

    INFO_TITLE: 'Instructions',
    WARN_TITLE: "Avertissement",
    ALERT_TITLE: `Quelque chose s'est mal passé`,
    ALERT_OK: 'OK',
    ALERT_CANCEL: 'ANNULER',
    ALERT_RETRY: 'RÉESSAYER',
    ALERT_QUIT: 'QUITTER',
    ALERT_IGNORE: "ANNULER",

    MEDIA_DEVICES_NOT_SUPPORTED: "Ce navigateur ne prend pas en charge les caméras ou les microphones",
    CAMERA_ERROR: "Erreur d\'accès au caméra",
    CAMERA_INSTRUCTIONS: ({err}) => `<p>[${err}]</p><ul><li>Assurez-vous que votre appareil dispose d'une caméra intégrée ou qu'une caméra est branchée</li><li>Vous devrez peut-être activer l'accès à la caméra pour <b>risr/</b>assess la confidentialité et paramètres de sécurité</li><li>Vous devrez peut-être activer l'accès à la caméra pour votre navigateur dans les paramètres de confidentialité et de sécurité de votre appareil</li></ul>`,
    MICROPHONE_ERROR: "Erreur d'accès au microphone",
    MICROPHONE_INSTRUCTIONS: ({err}) => `<p>[${err}]</p><ul><li>Assurez-vous que votre appareil dispose d'un microphone intégré ou qu'un microphone est branché</li><li>Vous devrez peut-être activer l'accès au microphone pour <b>risr/</b>assess la confidentialité et paramètres de sécurité</li><li>Vous devrez peut-être activer l'accès au microphone pour votre navigateur dans les paramètres de confidentialité et de sécurité de votre appareil</li></ul>`,
    NO_CAMERAS: 'Aucune caméra trouvée',
    NO_MICROPHONES: 'Aucun micro trouvé',
    SCREEN_SHARE_PERMISSION: "<p>Pour cet examen, vous devez autoriser le partage de votre écran avec l’examinateur·rice.</p><ul><li>La totalité de l’écran doit être partagée. Choisissez le partage complet si plusieurs options sont offertes; leur formulation peut varier.</li><li>Vous devrez peut-être autoriser le partage d'écran ou l'enregistrement d'écran pour <b>risr/</b>assess dans les paramètres de confidentialité et de sécurité de votre navigateur</li><li>Vous devrez peut-être autoriser le partage d'écran ou l'enregistrement d'écran pour votre navigateur dans les paramètres de confidentialité et de sécurité de votre appareil</li></ul>",
    SCREEN_SHARE_FAILED: `Le partage d'écran est actuellement bloqué`,

    VIEWER_RESET: 'RÉINITIALISER LA VUE',
    VIEWER_SELECT: 'SÉLECTIONNER UNE ACTION',
    VIEWER_PREV: 'PAGE PRÉCÉDENTE',
    VIEWER_NEXT: 'PAGE SUIVANTE',
    VIEWER_FULLSCREEN: 'BASCULER EN MODE PLEIN ÉCRAN',
    VIEWER_CLOSE: 'FERMER LA VISIONNEUSE',
    VIEWER_BROADCAST: 'DIFFUSER LES CHANGEMENTS',
    VIEWER_UNDO: 'ANNULER LES CHANGEMENTS',
    VIEWER_REDO: 'RÉTABLIR LES CHANGEMENTS',

    LOGOUT_MESSAGE: "<p>Vous n'êtes pas connecté·e. Cliquez sur Connexion ci-dessous.</p>",
    LOGIN_AGAIN: 'CONNEXION',
    P4B_BACKGROUNDED: `Cet onglet a été suspendu car un autre onglet d'examen <b>risr/</b>assess était actif. Cliquez sur OK pour reprendre.`,
    NEWER_VERSION_AVAILABLE: ({version, latest}) => `<p>Une nouvelle version de <b>risr/</b>assess est disponible</p><p>installée: ${version}<br/>disponible: ${latest}</p>`,
});
