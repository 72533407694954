import {registerLanguage} from '../utils-lang';

const plural = (x: number) => (x > 1 || x === 0 || x < -1);

registerLanguage('Română', {
    LOCALE: 'ro-RO',
    LOGIN_INSTRUCTIONS: '<h1>Introduceți datele de autentificare</h1>',
    LOGIN_CANDIDATE_ID_LABEL: 'Nume de utilizator',
    LOGIN_PASSWORD_LABEL: 'Parolă',
    LOGIN_BUTTON: 'Autentificare',
    FREE_SPACE: ({quota, used}) => `${used}MB spațiu folosit din ${quota}MB disponibil`,

    CHOOSE_INSTRUCTIONS: ({cid}) => `<p><b>Sunteți autentificat ca utilizator ${cid}</b></p>Dacă nu sunteți dumneavoastră, vă rugăm să vă deconectați și să vă reconectați folosind ID-ul de utilizator furnizat`,
    CHOOSE_PROCTORED_EXAMITY: `<h1>Vă rugăm să faceți clic pe „Începeți supravegherea” pentru a accesa platforma EXAMITY</h1><ul><li>Pentru a crea profilul dumneavoastră</li><li>Pentru a vă conecta cu supraveghetorul și a începe examenul</li></ul>`,
    CHOOSE_PROCTORED_GENERIC: (proctorName) => `<h1>Vă rugăm să faceți clic pe \u201cÎncepeți&nbsp;supravegherea\u201d pentru a accesa platforma ${proctorName}</h1>`,
    CHOOSE_PROCTORED_SUPPORT: `Dacă întâmpinați dificultăți în conectarea cu supraveghetorul, vă rugăm să contactați echipa de suport pentru examen`,
    CHOOSE_NO_EXAMS: `<h1>Nu există examene disponibile pentru dumneavoastră</h1>`,
    CHOOSE_SELECT_EXAM: `<h1>Vă rugăm să selectați examenul pe care îl susțineți</h1>`,
    CHOOSE_AUTO_START: `<h1>Vă rugăm să așteptați, veți fi conectat automat când examenul dumneavoastră este deschis</h1>`,
    CHOOSE_AUTO_SUPPORT: `Dacă nu sunteți conectat după ora de începere a examenului, vă rugăm să contactați echipa de suport pentru examen`,
    CHOOSE_MANUAL_START: `<h1>Introduceți PIN-ul examenului care v-a fost furnizat</h1>`,
    CHOOSE_MANUAL_SUPPORT: `Dacă întâmpinați dificultăți în începerea examenului, vă rugăm să contactați echipa de suport pentru examen`,
    CHOOSE_PRESS_START: `<h1>Faceți clic pe „Susțineți examenul ales” pentru a începe</h1>`,
    CHOOSE_EXAM_LABEL: 'Alegeți examenul',
    CHOOSE_MANUAL_PIN_LABEL: 'PIN examen',
    CHOOSE_AUTO_PIN_LABEL: 'PIN offline',
    CHOOSE_EXAM_BUTTON: 'Susțineți examenul ales',
    CHOOSE_LOGOUT_BUTTON: 'Deconectare',
    CHOOSE_PROCTOR_BUTTON: 'Începeți supravegherea',
    CHOOSE_EXAM_HINT: 'Alegeți examenul de susținut...',
    CHOOSE_WAITING_TO_START: 'Examene în așteptarea începerii',
    CHOOSE_ALREADY_STARTED: 'Examene deja începute',
    CHOOSE_OFFLINE_BUTTON: 'Modul offline',

    ERROR_NETWORK: 'Eroare de rețea',
    ERROR_USER_PASS: '<b>Autentificare eșuată. Nu există un astfel de cont sau ați furnizat date de autentificare invalide.</b>',
    ERROR_CONNECTION_TIMEOUT: 'Timp de conexiune expirat',
    ERROR_HTTP: ({code}) => `Eroare de server ${code ? ` (HTTP ${code})` : ''}`,
    ERROR_PROXY: 'Server ocupat, vă rugăm încercați din nou.',
    ERROR_UNKNOWN: ({message}) => `Eroare ${message ? ` [${message}]` : ''}`,
    ERROR_STORAGE: "Ați rămas fără spațiu de stocare. Vă rugăm să eliberați mai mult spațiu sau să migrați pe un alt dispozitiv.",
    ERROR_PIN: '<b>PIN incorect sau examen greșit ales<b>',
    ERROR_CANDIDATE_NOT_FOUND: 'ID-ul de utilizator introdus incorect sau nu este înregistrat pentru acest examen.',
    ERROR_RESEND: ({err}) => `<b>${err}</b><p>Serverul nu poate fi contactat. Puteți continua examenul offline și răspunsurile vor fi stocate local pe acest dispozitiv, dar trebuie să fie trimise la server înainte de a putea finaliza examenul. Nu veți putea vedea răspunsurile trimise anterior de pe un alt dispozitiv. Dacă se reface conexiunea la rețea, toate răspunsurile vor fi salvate automat pe server.</p>`,
    ERROR_DOWNLOAD: 'Imposibil de descărcat examenul',

    DOWNLOAD_TITLE: ({downloadCount}) => `Se descarcă ${downloadCount} examen${plural(downloadCount) ? 'e' : ''}, vă rugăm așteptați...`,
    DOWNLOAD_DESCRIPTION: ({freeSpace}) => `<p>Descărcarea poate dura câteva minute în funcție de conectivitatea la rețea</p>${freeSpace}`,
    DOWNLOAD_COUNT_EXCEEDED: ({examTitle}) => `Numărul de descărcări depășit: ${examTitle}`,

    PREPARING_TITLE: 'Pregătire examen, vă rugăm așteptați...',
    PREPARING_DESCRIPTION: ({freeSpace}) => `<p>Pregătirea poate dura câteva minute în funcție de specificațiile computerului<p>${freeSpace}`,

    CONTROL_OVERVIEW: 'PREZENTARE GENERALĂ',
    CONTROL_LANGUAGE: 'LIMBĂ',
    CONTROL_FINISH: 'FINALIZARE',
    CONTROL_CALCULATOR: 'CALCULATOR',
    CONTROL_COLOUR: 'CULOARE',
    CONTROL_VERSION: 'VERSIUNE',
    CONTROL_USERID: 'ID UTILIZATOR',
    CONTROL_PREVIOUS: 'ANTERIOR',
    CONTROL_NEXT: 'URMĂTOR',
    CONTROL_PREVIOUS_CASE: 'CAZ ANTERIOR',
    CONTROL_NEXT_CASE: 'CAZ URMĂTOR',
    CONTROL_PREVIOUS_CANDIDATE: 'CANDIDAT ANTERIOR',
    CONTROL_NEXT_CANDIDATE: 'CANDIDAT URMĂTOR',
    CONTROL_PREVIOUS_STATION: 'STAȚIE ANTERIOARĂ',
    CONTROL_NEXT_STATION: 'STAȚIE URMĂTOARE',
    CONTROL_NOTES: 'NOTIȚE',
    CONTROL_CONNECT: 'CONECTARE',
    CONTROL_CONNECTING: 'ÎN CURS DE CONECTARE',
    CONTROL_DISCONNECT: 'DECONECTARE',
    CONTROL_DISCONNECTING: 'ÎN CURS DE DECONECTARE',
    CONTROL_ID_VALIDATE: ({id}) => `VALIDAȚI ${id}`,
    CONTROL_ID_INVALIDATE: ({id}) => `INVALIDAȚI ${id}`,
    CONTROL_MUTE: 'MUT',
    CONTROL_UNMUTE: 'ACTIVARE SUNET',
    CONTROL_CAMERA: 'CAMERĂ',
    CONTROL_SPEAKERS: 'DIFUZOARE',
    CONTROL_MICROPHONE: 'MICROFON',
    CONTROL_HIGHLIGHT: '(DE)EVIDENȚIERE',

    ID_CHECK_INVALIDATE_CONFIRM: 'Sunteți sigur că doriți să invalidați acest ID?',

    TITLE_ROUND: 'rundă',
    TITLE_STATION: 'stație',
    TITLE_CIRCUIT: 'circuit',
    TITLE_CASE: ({n, m}) => `cazul&nbsp;<b>${n}</b>&nbsp;din&nbsp;<b>${m}</b>`,
    TITLE_CONNECT_TO: ({factor}) => `conectați-vă&nbsp;la&nbsp;${factor}`,

    NOTIFICATION_CONNECT: '<center>Faceți clic pe butonul <b>CONECTARE</b> de mai sus pentru a începe.</center>',
    NO_CANDIDATE: 'Niciun candidat pentru această rundă.',
    REST_STATION: 'Aceasta este o stație de odihnă.',

    MEETING_POOR_CONNECTION: 'Conexiune slabă: s-ar putea să aveți probleme cu calitatea audio și video.',
    MEETING_STATUS_ERROR: ({err}) => `Starea de ieșire a întâlnirii: ${err}`,

    TIMER_REMAINING: 'TIMP RĂMAS',
    TIMER_ELAPSED: 'TIMP SCURS',
    TIMER_ROUND_REMAINING: 'RUNDE RĂMASE',

    OVERVIEW_TITLE: 'Prezentare generală',
    OVERVIEW_NO_CANDIDATE: 'Niciun Candidat',
    RESOURCE_RESTRICTED_SHOW: 'Arată',
    RESOURCE_RESTRICTED_HIDE: 'Ascunde',

    ANSWER_OPTIONAL: 'opțional',
    ANSWER_REQUIRED: 'obligatoriu',
    ANSWER_TITLE: 'Răspuns',
    ANSWER_FLAG_TIP: 'Marchează această întrebare',
    SBA_ANSWER_TIP: 'Selectează acest răspuns',
    SBA_ELIMINATE_TIP: 'Elimină acest răspuns',
    NOT_ANSWERED: 'Fără raspuns',

    PAUSED_TITLE: 'Examen în pauză',
    PAUSED_DESCRIPTION: '<p>Examenul a fost <strong>pauzat</strong> la distanță de un administrator.<p>',

    STOPPED_TITLE: 'Examen Finalizat',
    STOPPED_CLEANUP: '<p>Curățare în curs, vă rugăm așteptați...<p>',

    MARKSHEET_INVALID: ({invalid}) => `Următoarele criterii obligatorii necesită un răspuns:<br><br><b>${invalid}</b><br><br><div style="text-align:left"><b>OK</b> - pentru a părăsi această foaie de evaluare.<br><b>ANULAȚI</b> - pentru a rămâne pe această foaie de evaluare și a vedea ce criterii lipsesc.</div>`,

    FINISH_TITLE: 'Gata de finalizare?',
    FINISH_DESCRIPTION: 'Faceți clic pe „Trimiteți examenul” înainte de a închide fila sau fereastra',
    FINISH_ANSWERED: 'Ați răspuns la toate întrebările',
    FINISH_UNANSWERED: ({totalUnanswered}) => `Aveți ${totalUnanswered} întrebări fără răspuns${plural(totalUnanswered) ? 'uri' : ''}`,
    FINISH_MANDATORY_UNANSWERED: ({totalUnanswered, mandatoryUnanswered}) => `Aveți ${totalUnanswered} întrebări fără răspuns${plural(totalUnanswered) ? 'uri' : ''} (${mandatoryUnanswered} obligatorii)`,
    FINISH_SUBMITTED: '<p><b>Toate răspunsurile dumneavoastră au fost trimise la server.</b></p>',
    FINISH_UNSUBMITTED: ({unsubmitted}) => `<p>Aveți <strong>${unsubmitted}</strong> răspuns${plural(unsubmitted) ? 'uri' : ''} care ${plural(unsubmitted) ? 'nu au fost trimise' : 'nu a fost trimis'} la server.</p><p>Este sigur să continuați examenul deoarece răspunsurile vor fi stocate pe dispozitivul dumneavoastră.</p>`,
    FINISH_CONTINUE: 'Continuați examenul',
    FINISH_NOW: 'Trimiteți examenul',

    INFO_TITLE: 'Instrucțiuni',
    WARN_TITLE: 'Avertizare',
    ALERT_TITLE: 'Ceva nu a funcționat corect',
    ALERT_OK: 'OK',
    ALERT_CANCEL: 'ANULAȚI',
    ALERT_RETRY: 'REÎNCERCAȚI',
    ALERT_QUIT: 'IEȘIȚI',
    ALERT_IGNORE: "IGNORAȚI",

    MEDIA_DEVICES_NOT_SUPPORTED: 'Acest browser nu suportă camere și microfoane',
    CAMERA_ERROR: 'Eroare la accesarea camerei',
    CAMERA_INSTRUCTIONS: ({err}) => `<p>[${err}]</p><ul><li>Asigurați-vă că dispozitivul dumneavoastră are o cameră încorporată sau că o cameră este conectată</li><li>S-ar putea să fie necesar să activați accesul la cameră pentru <b>risr/</b>assess în setările de confidențialitate și securitate ale browserului dumneavoastră</li><li>S-ar putea să fie necesar să activați accesul la cameră pentru browserul dumneavoastră în setările de confidențialitate și securitate ale dispozitivului</li></ul>`,
    MICROPHONE_ERROR: "Eroare la accesarea microfonului",
    MICROPHONE_INSTRUCTIONS: ({err}) => `<p>[${err}]</p><ul><li>Asigurați-vă că dispozitivul dumneavoastră are un microfon încorporat sau că un microfon este conectat</li><li>S-ar putea să fie necesar să activați accesul la microfon pentru <b>risr/</b>assess în setările de confidențialitate și securitate ale browserului dumneavoastră</li><li>S-ar putea să fie necesar să activați accesul la microfon pentru browserul dumneavoastră în setările de confidențialitate și securitate ale dispozitivului</li></ul>`,
    NO_CAMERAS: 'Nu s-au găsit camere',
    NO_MICROPHONES: 'Nu s-au găsit microfoane',
    SCREEN_SHARE_PERMISSION: '<p>Pentru acest examen trebuie să <i>permiteți</i> partajarea ecranului cu examinatorul.</p><ul><li>Trebuie să partajați întregul ecran, deci dacă vi se oferă opțiuni, ar trebui să selectați „întregul ecran”, „ecran complet” sau echivalent.</li><li>S-ar putea să fie necesar să permiteți „partajarea ecranului” sau „înregistrarea ecranului” pentru <b>risr/</b>assess în setările de confidențialitate și securitate ale browserului dumneavoastră</li><li>S-ar putea să fie necesar să permiteți „partajarea ecranului” sau „înregistrarea ecranului” pentru browserul dumneavoastră în setările de confidențialitate și securitate ale dispozitivului</li></ul>',
    SCREEN_SHARE_FAILED: '<b>Partajarea ecranului este în prezent <i>blocată</i>.</b>',

    VIEWER_RESET: 'RESETARE VIZUALIZARE',
    VIEWER_SELECT: 'SELECTAȚI ACȚIUNEA DE CLICK',
    VIEWER_PREV: 'PAGINA ANTERIOARĂ',
    VIEWER_NEXT: 'PAGINA URMĂTOARE',
    VIEWER_FULLSCREEN: 'ACTIVAȚI ECRAN COMPLET',
    VIEWER_CLOSE: 'ÎNCHIDEȚI VIZUALIZATORUL',
    VIEWER_BROADCAST: 'TRANSMITEȚI SCHIMBĂRILE',
    VIEWER_UNDO: 'ANULAȚI SCHIMBĂRILE',
    VIEWER_REDO: 'REFACEȚI SCHIMBĂRILE',

    LOGOUT_MESSAGE: '<p>Sunteți în prezent deconectat, faceți clic mai jos pentru a vă autentifica.</p>',
    LOGIN_AGAIN: 'AUTENTIFICARE DIN NOU',
    P4B_BACKGROUNDED: 'Această filă a fost suspendată deoarece o altă filă de examen <b>risr/</b>assess a fost activă. Faceți clic pe OK pentru a relua.',
    NEWER_VERSION_AVAILABLE: ({version, latest}) => `<p>O versiune mai nouă a <b>risr/</b>assess este disponibilă</p><p>instalată: ${version}<br/>disponibilă: ${latest}</p>`,
});
